



















































import VueAsyncComputed from 'vue-async-computed';
import {Component, Vue} from 'vue-property-decorator';
import {ArkStatsBackendClient, ResourceListDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import {ServerDto} from '@/logic/ArkServerManagerClient';
import {AuthBackendAPI, DiscordMemberDto} from '@/logic/authentication/AuthBackendAPI';
import RconMessageLogDto from "@/logic/ark-stats-backend/dto/rconmessagelog/RconMessageLogDto";

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class ServerMessages extends Vue {

    private dateFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
    } as const;
    private initialized = false;
    private history: RconMessageLogDto[] = [];
    private discordMembers: { [key: string]: DiscordMemberDto } = {};
    private servers: ServerDto[] = [];

    private server: string|null = null;
    private command: string|null = null;
    private sending = false;

    async created(): Promise<void> {
        await this.loadHistory();
    }

    getAuthenticationLink(): string {
        return "https://auth.domination-gaming.com/oauth/discord?redirect_uri=" + encodeURIComponent(window.location.href);
    }

    async loadHistory(): Promise<void> {
        this.history = (await ArkStatsBackendClient.getRconMessageLog({})).data;
        this.servers = (await ArkStatsBackendClient.getGenericObject<ResourceListDto<ServerDto>>("server", {
            fields: ['id', 'name'],
            orderBy: 'id'
        })).data;
        this.history.forEach(history => {
            if(history.executorDiscordId) {
                AuthBackendAPI.getDiscordMemberCached(history.executorDiscordId).then(discordMember => {
                    Vue.set(this.discordMembers, history.executorDiscordId as string, discordMember);
                });
            }
        });
        this.initialized = true;
    }

    isAdmin(): boolean {
        return AuthBackendAPI.isAdmin(this.$store.state.currentUserState);
    }

    isValid(command: string, server: string): boolean {
        return !!(command && server);
    }

    getDiscordMemberName(discordId: string): string {
        if(!this.discordMembers[discordId]) {
            return `discord:${discordId}`;
        }
        const member = this.discordMembers[discordId];
        return `${member.user.username}#${member.user.discriminator} ${member.nick ? '(' + member.nick + ')' : ''}`
    }

    public send(command: string, server: string): void {
        this.sending = true;
        ArkStatsBackendClient.sendRcon(server, command).then(() => {
            return this.loadHistory();
        }).finally(() => {
            this.sending = false;
        });
    }

    public localDate(timestamp: string | Date): string {
        return new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(new Date(timestamp));
    }
}
